<template>
  <div class="contact_screen">
    <Navbar />
    <Breadcrumb :breadcrumb="state.listBreadcrumbs" />
    <div class="container">
      <div class="main-area">
        <div class="content">
          <img src="@/assets/images/contact/title.svg" alt="">
          <p>
            このサービスに関するお問い合わせは、各運営事務局の問い合わせ先 <br/>
            または事務局のSlackにてお問い合わせください。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/contact/Contact.ts">
</script>

<style lang="scss"  src="@/presentation/views/contact/Contact.scss" scoped>
</style>
