import { reactive, defineComponent } from "vue";
import Navbar from "@/presentation/components/navigation/Navigation.vue";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "ContactScreen",
    components: {
        Breadcrumb: Breadcrumb,
        Navbar: Navbar
    },
    setup: function () {
        var state = reactive({
            listBreadcrumbs: [
                {
                    name: "TOP",
                    route: "/"
                },
                {
                    name: "お問い合わせ",
                    route: routePath.CONTACT
                }
            ]
        });
        return {
            state: state
        };
    }
});
